.tracking-link-note-container {
  width: 80%;
  margin: 10px auto; }
  .tracking-link-note-container .note-title {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .tracking-link-note-container .note-title h4 {
      font-family: "Spartan","Robotica", sans-serif;
      font-weight: bold;
      font-size: 18px;
      margin: 0; }
  .tracking-link-note-container .note-text {
    margin-top: 20px;
    font-family: "Spartan","Robotica", sans-serif;
    font-size: 16px;
    line-height: 1.5em; }
