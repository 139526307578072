.white-label-list-container {
    height: 85vh;
    background-color: white;
    padding: 3%;
}

.white-label-list-container .action-button {
    margin-bottom: 3%;
}

.white-label-list-container .title {
    margin-top: 0;
}

.white-label-form-container {
    height: 90vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}

.white-label-form-wrapper {
    padding: 6% 3%;
    display: grid;
    grid-template-columns: 1fr;
    max-height: 90vh;
    overflow: auto;
}

.white-label-form-title {
  text-align: center;
  text-decoration: bold;
  text-transform: uppercase;
}

.white-label-form-input-row {
    display: grid;
    grid-template-columns: 0.6fr 1fr;
    grid-template-rows: 1fr;
    padding: 3% 2%;
}

.white-label-form-input-row .left {
    text-align: left;
}

.white-label-form-input-row .right {
    text-align: right;
}

.customEvaInput {
    border: 0;
    font-family: "Roboto";
    font-size: 20px !important;
    font-weight: 400;
    width: 70%;
    color: gray !important;
    height: 30px;
    border-radius: 30px !important;
    padding: 20px !important;
    vertical-align: middle;
  }

  .white-label-form-dropdown {
    font-family: "Roboto";
    font-size: 20px !important;
    font-weight: 400;
    width: 50%;
    color: gray !important;
    height: 40px;
    border-radius: 30px !important;
  }

  .customEvaInput .small {
    width: 30%;
  }

  .white-label-form-input-row .color {
    width: 90%;
  }

  .color-wheel-select-wrapper {
    position: absolute;
    width: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    display: grid;
    margin: auto;
    top: 0;
    left: 0;
  }

  .color-wheel-select-close {
    z-index: 300 !important;
    position: absolute;
    cursor: pointer;
  }

  .color-wheel-select {
    margin: auto;
  }

  .color-wheel-icon-wrapper {
    text-align: left;
    margin: 6px;
  }

  .customEvaInputColor {
    display: grid;
    grid-template-columns: 1fr 0.7fr;
    grid-template-rows: 1fr;
  }

  .customEvaInputLabel {
    font-family: "Roboto";
    font-size: 20px !important;
    font-weight: 400;
    height: 30px;
    padding: 8px 25px !important;
    vertical-align: middle;
  }

  .mia-selection-container {
    display: grid;
    border-radius: 50px;
    overflow: hidden;
    background-color: white;
    width: 70%;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    font-family: "Roboto";
    font-size: 20px !important;
    font-weight: 400;
    color: gray !important;
    height: 40px;
  }

  .mia-selection-item {
    text-align: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: grid;
  }

  .white-label-form-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 10%;
    text-align: center;
  }

  .white-label-form-button-container .action-button-dark {
    border: 0 !important;
    font-family: "Roboto";
    font-size: 20px !important;
    font-weight: 400;
    width: 50%;
    color: white;
    height: 45px;
    border-radius: 30px !important;
    background-color: black;
    display: grid;
    margin: auto;
    cursor: pointer;
  }

  .white-label-form-button-container .action-button {
    border: 0 !important;
    font-family: "Roboto";
    font-size: 20px !important;
    font-weight: 400;
    width: 50%;
    color: black;
    height: 45px;
    border-radius: 30px !important;
    background-color: var(--eva-yellow);
    display: grid;
    margin: auto;
    cursor: pointer;
  }