.live-tracker-help-screen-table {
  width: 100%;
  height: 100%;
  text-align: center; }

.live-tracker-help-screen-back-arrow {
  -webkit-mask: url(../../SVGs/back_arrow.svg) no-repeat center;
  mask: url(../../SVGs/back_arrow.svg) no-repeat center;
  float: left;
  margin: 38px 0 0 20px;
  height: 20px;
  width: 20px;
  background-color: var(--eva-yellow); }

.live-tracker-help-screen-title {
  font-family: "graphie",sans-serif !important;
  font-weight: bold;
  font-size: 1.8em;
  margin: 30px 0 0 10px;
  margin-bottom: 15%;
  float: left; }

.live-tracker-help-screen-contact {
  font-size: 1.2em;
  padding: 2% 13%;
  text-align: left; }

.live-tracker-message-sent {
  height: 60vh;
  padding-top: 30%;
  font-size: 1.2em; }

.live-tracker-option-paragraph {
  padding: 10%;
  font-size: 1.3em; }

/* MOBILE ADJUSTMENTS: NOTE - INCLUDES ALL OTHER CSS FORM DESKTOP */
@media only screen and (max-device-width: 1000px) {
  .help-screen-container {
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 9;
    height: 100vh;
    padding-top: 20px;
    width: 100%; }
  .live-tracker-help-screen-title {
    margin-bottom: 0; }
  .help-screen-smaller-margin-mobile {
    height: 15%; } }

@media only screen and (max-device-width: 1000px) and (orientation: landscape) {
  .help-screen-container {
    height: auto; } }
