.invalid-link-container {
  background-color: white;
  text-align: center;
  height: 100%;
  min-height: 100%;
  font-family: "graphie", sans-serif !important;
  padding: 5%; }

.invalid-link-title {
  font-family: "graphie",sans-serif !important;
  color: black;
  font-weight: bold;
  font-size: 2.0em; }

.underlined-eva {
  border-bottom: 4px solid var(--eva-yellow);
  border-radius: 2px; }

.invalid-link-midsection-wrapper {
  width: 80%;
  display: inline-block;
  margin-top: 30px; }

.invalid-link-container > .invalid-link-midsection-wrapper > .phone-img {
  height: 25vh;
  width: 10vw;
  float: left;
  margin-top: 6%;
  -webkit-mask: url(../SVGs/phone.svg) no-repeat top;
  mask: url(../SVGs/phone.svg) no-repeat top;
  background-color: var(--eva-yellow); }

.main-text-container {
  margin: auto;
  font-family: "graphie",sans-serif !important;
  width: 100%;
  margin-top: 15px; }

.main-text-title {
  font-family: "graphie",sans-serif !important;
  color: black;
  font-size: 1.5em; }

.main-text-paragraph {
  padding-top: 15px;
  font-family: "graphie",sans-serif !important;
  color: black;
  font-size: 1em; }

.ads-div-invalid {
  display: block;
  margin: 20px 0; }

.main-text-contact {
  padding-top: 15px; }

.marketing-wrapper {
  width: 100%;
  text-align: center; }

.contact-link {
  font-family: "graphie",sans-serif !important;
  font-weight: bold;
  color: black; }

/* MOBILE ADJUSTMENTS: NOTE - INCLUDES ALL OTHER CSS FORM DESKTOP */
@media only screen and (max-device-width: 1000px) {
  .main-text-container {
    justify-content: center; }
  .invalid-link-container {
    padding: 8%; }
  .invalid-link-midsection-wrapper {
    width: 100%;
    display: flex;
    margin-top: 0;
    justify-content: center; }
  .invalid-link-container > .invalid-link-midsection-wrapper > .phone-img {
    width: 28vw; }
  .ads-div-invalid {
    display: block; } }

@media only screen and (max-device-width: 1000px) and (orientation: landscape) {
  .main-text-container {
    justify-content: center; }
  .invalid-link-container {
    padding: 3%; }
  .invalid-link-midsection-wrapper {
    width: 100%;
    margin-top: 0%;
    padding-bottom: 0%;
    display: flex;
    justify-content: center; }
  .invalid-link-container > .invalid-link-midsection-wrapper > .phone-img {
    width: 18vw;
    height: 55vh; }
  .marketing-wrapper {
    width: 100%;
    display: block; }
  .ads-div-invalid {
    display: block; } }

@media only screen and (max-device-height: 750px) and (orientation: portrait) {
  .ad-img {
    display: none; } }
