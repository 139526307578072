/* CHANGES FOR https://phabricator.eva.cab/T2333 */
.footer-transparent {
    padding-left: 0px;
    position: relative !important;
    background-color: transparent !important;
    border-top: transparent !important;
}

.footer-transparent p {
    position: relative;
    text-align: right;
    bottom: 0;
    right: 0;
    margin-right: 2%;
    margin-bottom: 1.2%;
}

/* CHANGES FOR https://phabricator.eva.cab/T2324 */
.navbar-default {
    position: relative !important;
    background-color: rgba(255, 255, 255, 0,96);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.wrapper {
    position: relative;
    top: 0px;
    height: 100vh;
}

/* LIVETRACKER FOR MOBILE FIXES */
@media all and (max-width:800px) {
    .navbar-brand {
        width: 50% !important;
    }
    
    .dropdown-with-icons {
        height: 10% !important;
    }

    .change-lang-button a {
        color: white;
    }
}

/* OTHERS */
.sidebar:after, .bootstrap-navbar:after {
    /* Sidebar is deep black */
    content: none !important;
}
