section.tracking-link-status-container {
  width: 80%;
  margin: 10px auto; }
  section.tracking-link-status-container .status-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; }
    section.tracking-link-status-container .status-title h4 {
      font-family: "Spartan","Robotica", sans-serif;
      font-weight: bold;
      font-size: 18px;
      margin: 0; }
  section.tracking-link-status-container .status-container p:not(.disclaimer-map) {
    font-size: 16px;
    line-height: 1.5em;
    margin: 1em 0;
    text-align: center; }
  section.tracking-link-status-container .status-container .info-img-wrapper {
    padding: 0 10% 0 10%; }
    section.tracking-link-status-container .status-container .info-img-wrapper .loading-img,
    section.tracking-link-status-container .status-container .info-img-wrapper .loading-img-complete,
    section.tracking-link-status-container .status-container .info-img-wrapper .people-img,
    section.tracking-link-status-container .status-container .info-img-wrapper .info-img {
      margin: auto;
      width: 180px;
      height: 150px;
      background-color: var(--eva-yellow); }
    section.tracking-link-status-container .status-container .info-img-wrapper .info-img {
      -webkit-mask: url(../../../SVGs/sad_face.svg) no-repeat center;
      mask: url(../../../SVGs/sad_face.svg) no-repeat center; }
    section.tracking-link-status-container .status-container .info-img-wrapper .loading-img {
      -webkit-mask: url(../../../SVGs/puff.svg) no-repeat center;
      mask: url(../../../SVGs/puff.svg) no-repeat center; }
    section.tracking-link-status-container .status-container .info-img-wrapper .loading-img-complete {
      -webkit-mask: url(../../../SVGs/rings.svg) no-repeat center;
      mask: url(../../../SVGs/rings.svg) no-repeat center; }
    section.tracking-link-status-container .status-container .info-img-wrapper .people-img {
      -webkit-mask: url(../../../SVGs/community.svg) no-repeat center;
      mask: url(../../../SVGs/community.svg) no-repeat center; }
  section.tracking-link-status-container p.disclaimer-map {
    margin-top: 25px;
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding: 0 1em; }
