.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh; }

.login-form {
  padding: 0 30px 15px 30px;
  width: 400px; }

.login-form .error-field {
  border: 1px solid red !important; }

.center-screen label {
  float: left;
  font-size: 14px !important;
  text-transform: none !important; }

.logo-img {
  width: 30%;
  margin-top: 15px;
  margin-bottom: 30px; }

.center-screen .title {
  margin: auto !important;
  font-weight: 700 !important; }

.center-screen .card {
  border-radius: 25px !important;
  box-shadow: unset !important; }

.center-screen input {
  border-radius: 25px !important; }

.center-screen button {
  border-radius: 25px !important; }

.center-screen p {
  padding: 5px 0;
  font-size: 14px; }

.yellow-line {
  background-color: #ffc002;
  margin: 15px 15px 15px 85px;
  height: 5px; }

.terms-parent {
  display: flex;
  justify-content: center;
  text-align: center; }

.terms-label {
  display: inline-block;
  margin: 0px 10px 0px 0px;
  padding: 2px 0px 0px 0px;
  border: 0px;
  line-height: 44px;
  cursor: pointer; }

.terms-control {
  display: inline-block;
  cursor: pointer; }
