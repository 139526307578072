.account-settings-container {
  min-height: 85vh;
  padding: 6%;
  text-align: center;
  background-color: white; }
  .account-settings-container .col-d {
    padding: 30px;
    text-align: center; }
  .account-settings-container .setting-title {
    font-size: 1.8em; }
  .account-settings-container .setting-subtitle {
    font-size: 1em;
    color: #888; }
  .account-settings-container .checkbox-d {
    cursor: pointer; }
  .account-settings-container .checkbox-d-label {
    margin-left: 10px; }
  .account-settings-container .btn-group label {
    border-color: #444; }
