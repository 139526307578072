/* STYLES FOR TABLE COMPONENTS */

.p-3 {
    margin: 1rem !important;
  }

.DateRangePicker_picker {
  z-index: 100 !important
}

/* STYLES FOR TABLE API COMPONENT */
.center-text {
  text-align: center !important;
}

.page-section {
  margin-top: 3vh !important;
}

.page-section-input {
  width: 4vw;
  margin-left: 1vw;
}

.rows-per-page-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-filter {
  padding: 1%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.default-filter {
  padding: 1%;
  margin-top: 3%;
  margin-bottom: 3%;
  width: 75%;
  height: 30px;
}

.small-triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  float: right;
  margin-right: 25%;
}

.table-head {
  max-height: 80px;
  display: inline-block;
  position: absolute;
  z-index: 2;
  background-color: white;
}