.import-orders-form-container {
    height: 85vh;
    padding: 5%;
    text-align: center;
}

.import-orders-form-wrapper {
    width: 60%;
    display: inline-block;
    text-align: left;
}

.import-orders-form-wrapper .title {
    font-family: "Roboto";
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 10px;
    height: 40px;
}

.import-orders-form-wrapper .title-line {
    width: 150px;
    height: 5px;
    background-color: rgba(255, 192, 2, 1);
    margin-bottom: 30px;
    margin-left: 0;
}

.import-orders-form-wrapper .autocomplete-results {
    position: absolute;
    width: 30%;
}

.import-orders-form-wrapper .input-form {
        border: 0 !important;
        font-family: "Roboto";
        font-size: 20px !important;
        font-weight: 400;
        width: 100%;
        color: gray !important;
        height: 30px ;
        border-radius: 30px !important;
        padding: 20px !important;
        margin-bottom: 30px;
}

.import-orders-form-wrapper .file-upload-container {
    text-align: center;
}

.import-orders-form-wrapper .confirm-button {
    float: right;
    background-color: rgba(255, 192, 2, 1);
    border-radius: 50px;
    padding: 10px;
    min-width: 200px;
    display: block;
    border: 0;
    outline: none;
    color: rgba(255, 255, 255, 1);
    display: grid;
    margin-top: 30px;
}

.import-orders-form-wrapper .confirm-button-text {
    font-size: 18px;
    font-weight: 700;
    margin: auto;
}

