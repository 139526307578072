/* STYLES FOR THE LIVE TRACKER MAP */
/* VALUES */
:root {
  --eva-yellow: rgb(255,193,22)
; }

/* DESKTOP */
.text-center {
  text-align: center; }

.uppercase {
  text-transform: uppercase; }

.over-map-div {
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 20px;
  border: 1px solid #BBB;
  z-index: 1;
  position: relative;
  padding: 20px; }

#eta-label {
  top: 15px;
  left: 15px;
  opacity: 0.9;
  float: left; }
