.invoices-card-container {
  margin-top: 40px;
  margin-left: 20px;
  height: fit-content;
  max-width: 50%;
}

@media (min-width: 992px) {
  .invoices-elements {
    margin-right: 60%;
  }

}

.toggle-text:hover {
  cursor: pointer;
}

.invoices-card-container > div {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.invoices-card-container > div.open {
  height: fit-content;
}