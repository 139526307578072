.tracking-link-message-form-container {
  text-align: center;
  padding: 3% 10%; }

.tracking-link-message-form-table {
  display: inline-block;
  width: 100%; }

.tracking-link-message-form-table tr {
  display: inline-block;
  width: 80%; }

.tracking-link-message-form-table td {
  display: inline-block;
  padding: 2%;
  width: 100%; }
