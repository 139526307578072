.ad-container {
  text-align: center;
  background-color: white;
  border-radius: 30px;
  margin: 80px; }

.ride-share-app {
  font-family: "Spartan","Robotica", sans-serif !important;
  display: block;
  padding: 0;
  width: 80%;
  margin: auto; }

.ad-img {
  width: 60%;
  height: auto;
  max-width: 300px;
  display: inline-block; }

/* MOBILE ADJUSTMENTS: NOTE - INCLUDES ALL OTHER CSS FORM DESKTOP */
@media only screen and (max-width: 420px) {
  .eva-y-button-wrapper {
    width: 100%;
    margin: 0; }
  .ad-container {
    margin: 20px; } }

@media only screen and (max-height: 750px) and (orientation: landscape) {
  .ad-img {
    display: none; }
  .ad-container {
    margin: 20px; } }
