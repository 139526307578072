.separator-wrapper {
  width: 100%;
  height: 21px;
  padding: 10px 0; }

.separator {
  width: 80%;
  height: 1px;
  background-color: var(--eva-yellow);
  border-radius: 10%;
  margin: auto; }
