.tracking-link-drawer-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* MOBILE ADJUSTMENTS: NOTE - INCLUDES ALL OTHER CSS FORM DESKTOP */ }
  .tracking-link-drawer-container .drawer-help-btn {
    position: relative;
    right: -80%;
    top: 54px;
    border: none;
    background-color: transparent;
    height: 16px;
    margin: 0;
    padding: 0; }
    .tracking-link-drawer-container .drawer-help-btn span {
      float: left;
      font-family: "graphie", sans-serif !important;
      font-size: 14px;
      line-height: 16px;
      font-weight: bold;
      height: 16px; }
    .tracking-link-drawer-container .drawer-help-btn img {
      display: block;
      margin: 0;
      padding: 0;
      width: 16px;
      height: 16px; }
  .tracking-link-drawer-container .live-tracker-help-screen-back-arrow-title {
    -webkit-mask: url(../SVGs/back_arrow.svg) no-repeat center;
    mask: url(../SVGs/back_arrow.svg) no-repeat center;
    float: left;
    height: 10px;
    width: 20px;
    margin-top: 3px;
    background-color: var(--eva-yellow);
    transform: rotate(180deg); }
  @media only screen and (max-device-width: 1000px) {
    .tracking-link-drawer-container .drawer-help-btn {
      display: none; } }
