section.tracking-link-addresses-container {
  width: 80%;
  margin: 10px auto; }
  section.tracking-link-addresses-container .addresses-title {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    section.tracking-link-addresses-container .addresses-title h4 {
      font-family: "Spartan","Robotica", sans-serif;
      font-weight: bold;
      font-size: 18px;
      margin: 0; }
  section.tracking-link-addresses-container .addresses-content {
    margin-top: 20px;
    width: 100%; }
    section.tracking-link-addresses-container .addresses-content .address-box {
      display: flex;
      align-items: center; }
      section.tracking-link-addresses-container .addresses-content .address-box span.dot {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--eva-yellow);
        margin: 10px 20px 10px 0;
        min-width: 0;
        min-height: 0; }
      section.tracking-link-addresses-container .addresses-content .address-box p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline;
        font-family: "Spartan","Robotica", sans-serif !important;
        font-size: 14px;
        line-height: 14px;
        width: calc(95% - 32px);
        background-color: rgba(246, 244, 244, 0.5);
        border-radius: 50px;
        padding: 0.5em 2em;
        margin: 0; }
  section.tracking-link-addresses-container .vertical-line-block {
    display: block;
    height: 40px;
    border-left: 1px solid var(--eva-yellow);
    margin-left: calc(12px / 2); }
