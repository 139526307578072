#mia-form-file-upload {
    height: 16rem;
    width: 140rem;
    max-width: 100%;
    text-align: center;
    position: relative;
  }
  
  #mia-input-file-upload {
    display: none;
  }
  
  #mia-label-file-upload {
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: rgba(255, 192, 2, 0.2);
    background-color:rgba(255, 192, 2, 0.1);
  }
  
  #mia-label-file-upload.mia-drag-active {
    background-color: rgba(255, 192, 2, 0.3);
  }
  
  .mia-upload-button {
    cursor: pointer;
    text-decoration: underline;
    padding: 0.25rem;
    font-size: 1.2rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
    margin: 5px;
  }
  
  #mia-drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }