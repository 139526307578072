section.tracking-link-driver-info-container {
  width: 80%;
  margin: 10px auto; }
  section.tracking-link-driver-info-container .driver-title {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    section.tracking-link-driver-info-container .driver-title div.name-and-stars-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      max-width: calc(100% - 45px); }
      section.tracking-link-driver-info-container .driver-title div.name-and-stars-group h4 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline;
        font-family: "Spartan","Robotica", sans-serif;
        font-weight: bold;
        font-size: 18px;
        margin: 0 20px 0 0; }
      section.tracking-link-driver-info-container .driver-title div.name-and-stars-group div.stars-wrapper {
        margin: 0;
        padding: 5px 0 0 0;
        display: inline-block; }
      section.tracking-link-driver-info-container .driver-title div.name-and-stars-group span.star-div-driver {
        -webkit-mask: url(../../../SVGs/star.svg) no-repeat top;
        mask: url(../../../SVGs/star.svg) no-repeat top;
        width: 15px;
        height: 15px;
        margin-right: 8px;
        display: inline-block; }
  section.tracking-link-driver-info-container div.driver-content {
    margin-top: 20px;
    display: flex;
    align-items: center; }
    section.tracking-link-driver-info-container div.driver-content .driver-picture-container {
      float: left;
      aspect-ratio: 1;
      width: 30%;
      height: auto;
      border-radius: 50%;
      background-color: #fafafa;
      color: transparent; }
    section.tracking-link-driver-info-container div.driver-content .driver-picture {
      height: 100%;
      width: 100%;
      min-height: 100%;
      min-width: 100%;
      border-radius: 50%;
      object-fit: cover; }
    section.tracking-link-driver-info-container div.driver-content .driver-brand-and-call-icon-wrapper {
      width: 60%;
      margin: 0 5%;
      font-family: "Spartan","Robotica", sans-serif !important;
      color: #505050;
      display: inline-block;
      font-size: 16px;
      margin: 0 auto;
      text-align: center; }
      section.tracking-link-driver-info-container div.driver-content .driver-brand-and-call-icon-wrapper p:first-of-type {
        margin: 0;
        font-weight: 600; }
    section.tracking-link-driver-info-container div.driver-content .fa,
    section.tracking-link-driver-info-container div.driver-content .fa-phone {
      /* Hard coding a fix for RoundButton */
      width: 100%; }
