.progression-tracker-container {
  padding: 0;
  margin: 30px auto 0 auto;
  height: 100%;
  width: 100%;
  transition: 0.5s ease; }
  .progression-tracker-container .vertical-stepper {
    position: initial;
    display: block;
    margin: auto;
    width: 100%;
    height: 100%; }
    .progression-tracker-container .vertical-stepper .step > img.status-icon {
      width: 35px;
      height: auto;
      margin: 0; }
    .progression-tracker-container .vertical-stepper .step > .text-wrapper {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      padding-left: 20px; }
    .progression-tracker-container .vertical-stepper .step > .text-wrapper > p {
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      margin: 0; }
    .progression-tracker-container .vertical-stepper .step > .text-wrapper > p:first-child {
      font-size: 1.15em;
      width: 80%; }
    .progression-tracker-container .vertical-stepper .step > .text-wrapper > p:last-child {
      font-size: 0.9em;
      width: 20%;
      text-align: right;
      margin-left: auto; }
    .progression-tracker-container .vertical-stepper .active-step > .text-wrapper > p {
      color: #282828; }
    .progression-tracker-container .vertical-stepper .active-step > .text-wrapper > p:first-child {
      font-size: 1.25em; }
    .progression-tracker-container .vertical-stepper .active-step > .text-wrapper > p:last-child {
      font-size: 1em; }
    .progression-tracker-container .vertical-stepper .in-between-line {
      min-width: 2px;
      height: 35px;
      margin: 10px 0 10px 16.5px;
      border-left: 2px solid #919191; }
    .progression-tracker-container .vertical-stepper .in-between-line > span {
      margin-left: 38px;
      line-height: 35px;
      color: #282828; }
    .progression-tracker-container .vertical-stepper .done-step > .text-wrapper > p {
      color: #646464; }
    .progression-tracker-container .vertical-stepper .done-step-line {
      border-left: 2px solid var(--eva-yellow); }
    .progression-tracker-container .vertical-stepper .step > .car-icon {
      -webkit-mask: url(../../../SVGs/car_icon.svg) no-repeat top;
      mask: url(../../../SVGs/car_icon.svg) no-repeat top;
      display: inline-block;
      background-color: grey;
      height: 35px;
      width: 35px;
      margin: 0;
      vertical-align: middle; }
      .progression-tracker-container .vertical-stepper .step > .car-icon + .text-wrapper {
        width: calc(100% - 35px); }
    .progression-tracker-container .vertical-stepper .active-step > .car-icon {
      display: inline-block;
      background-color: var(--eva-yellow);
      height: 40px;
      width: 40px;
      margin: 0;
      vertical-align: middle; }
      .progression-tracker-container .vertical-stepper .active-step > .car-icon + .text-wrapper {
        width: calc(100% - 40px); }
    .progression-tracker-container .vertical-stepper .done-step > .car-icon {
      background-color: var(--eva-yellow); }
    .progression-tracker-container .vertical-stepper .step > .route-icon {
      -webkit-mask: url(../../../SVGs/route_icon.svg) no-repeat top;
      mask: url(../../../SVGs/route_icon.svg) no-repeat top;
      display: inline-block;
      background-color: grey;
      height: 35px;
      width: 35px;
      margin: 0;
      vertical-align: middle; }
      .progression-tracker-container .vertical-stepper .step > .route-icon + .text-wrapper {
        width: calc(100% - 35px); }
    .progression-tracker-container .vertical-stepper .active-step > .route-icon {
      display: inline-block;
      background-color: var(--eva-yellow);
      height: 40px;
      width: 40px;
      margin: 0;
      vertical-align: middle; }
      .progression-tracker-container .vertical-stepper .active-step > .route-icon + .text-wrapper {
        width: calc(100% - 40px); }
    .progression-tracker-container .vertical-stepper .done-step > .route-icon {
      background-color: var(--eva-yellow); }
    .progression-tracker-container .vertical-stepper .step > .rider-icon {
      -webkit-mask: url(../../../SVGs/rider_icon.svg) no-repeat top;
      mask: url(../../../SVGs/rider_icon.svg) no-repeat top;
      display: inline-block;
      background-color: grey;
      height: calc(35px - 5px);
      width: calc(35px - 5px);
      margin: 2.5px;
      vertical-align: middle; }
      .progression-tracker-container .vertical-stepper .step > .rider-icon + .text-wrapper {
        width: calc(100% - 35px); }
    .progression-tracker-container .vertical-stepper .active-step > .rider-icon {
      display: inline-block;
      background-color: var(--eva-yellow);
      height: calc(40px - 5px);
      width: calc(40px - 5px);
      margin: 2.5px;
      vertical-align: middle; }
      .progression-tracker-container .vertical-stepper .active-step > .rider-icon + .text-wrapper {
        width: calc(100% - 40px); }
    .progression-tracker-container .vertical-stepper .done-step > .rider-icon {
      background-color: var(--eva-yellow); }
    .progression-tracker-container .vertical-stepper .step > .delivery-icon {
      -webkit-mask: url(../../../SVGs/delivery_icon.svg) no-repeat top;
      mask: url(../../../SVGs/delivery_icon.svg) no-repeat top;
      display: inline-block;
      background-color: grey;
      height: calc(35px - 5px);
      width: calc(35px - 5px);
      margin: 2.5px;
      vertical-align: middle; }
      .progression-tracker-container .vertical-stepper .step > .delivery-icon + .text-wrapper {
        width: calc(100% - 35px); }
    .progression-tracker-container .vertical-stepper .active-step > .delivery-icon {
      display: inline-block;
      background-color: var(--eva-yellow);
      height: calc(40px - 5px);
      width: calc(40px - 5px);
      margin: 2.5px;
      vertical-align: middle; }
      .progression-tracker-container .vertical-stepper .active-step > .delivery-icon + .text-wrapper {
        width: calc(100% - 40px); }
    .progression-tracker-container .vertical-stepper .done-step > .delivery-icon {
      background-color: var(--eva-yellow); }
    .progression-tracker-container .vertical-stepper .step > .flag-icon {
      -webkit-mask: url(../../../SVGs/flag_icon.svg) no-repeat top;
      mask: url(../../../SVGs/flag_icon.svg) no-repeat top;
      display: inline-block;
      background-color: grey;
      height: calc(35px - 5px);
      width: calc(35px - 5px);
      margin: 2.5px;
      vertical-align: middle; }
      .progression-tracker-container .vertical-stepper .step > .flag-icon + .text-wrapper {
        width: calc(100% - 35px); }
    .progression-tracker-container .vertical-stepper .active-step > .flag-icon {
      display: inline-block;
      background-color: var(--eva-yellow);
      height: calc(40px - 5px);
      width: calc(40px - 5px);
      margin: 2.5px;
      vertical-align: middle; }
      .progression-tracker-container .vertical-stepper .active-step > .flag-icon + .text-wrapper {
        width: calc(100% - 40px); }
    .progression-tracker-container .vertical-stepper .done-step > .flag-icon {
      background-color: var(--eva-yellow); }
  .progression-tracker-container p.estimated-time-of-arrival {
    font-family: "Spartan","Robotica", sans-serif !important;
    font-size: 1.3em;
    margin: 40px auto 20px auto;
    padding: 0;
    text-align: center;
    width: 90%; }
    @media only screen and (min-device-width: 600px) {
      .progression-tracker-container p.estimated-time-of-arrival {
        padding: 0 20px; } }
