.ride-screen-wrapper {
  width: 100%;
  margin-bottom: 50px; }

.ride-screen-wrapper > .title > h1 {
  font-family: "Spartan","Robotica", sans-serif !important;
  color: var(--eva-yellow);
  font-weight: bold;
  font-size: 2.1em;
  width: 70%;
  margin: 0.75em 0 0.5em 1.25em;
  margin-left: 2em; }

.disclaimer {
  font-size: 16px;
  line-height: 1.5em;
  margin: 2em 2em;
  text-align: center;
  font-family: "Spartan","Robotica",sans-serif;
  font-weight: 400; }

@media only screen and (max-device-width: 1000px) {
  .ride-screen-wrapper > .title > h1 {
    font-size: 2em;
    width: 100%;
    text-align: center;
    margin: 1em 0 0.5em 0; } }
