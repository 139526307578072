.scheduled-screen-wrapper {
  width: 100%;
  max-width: 100%;
  padding: 15% 0;
  text-align: center; }

.scheduled-screen-table tr {
  width: 100%; }

.countdown-container {
  padding: 5% 0 0 0; }

.countdown-labels {
  padding: 5%;
  text-align: center;
  width: 415px;
  display: inline-block; }

.countdown-labels span {
  text-align: center;
  width: 25%;
  display: inline-block; }

.scheduled-title {
  font-family: "graphie", sans-serif !important;
  font-weight: bold;
  font-size: 2em; }

.scheduled-datetime {
  font-family: "graphie", sans-serif !important;
  font-size: 2em;
  margin: 0; }

.scheduled-datetime-alt {
  font-family: "graphie", sans-serif !important;
  font-size: 1.3em;
  margin: 0; }
  .scheduled-datetime-alt strong {
    font-size: 1.1em;
    font-weight: 600; }

.scheduled-body {
  padding: 8% 0 3% 0;
  margin: 0;
  font-family: "graphie", sans-serif !important;
  opacity: 0.6;
  font-size: 1.4em; }

#tracking-id {
  padding: 15% 0 3% 0; }

.scheduled-body-alt {
  padding: 0;
  font-family: "graphie", sans-serif !important;
  opacity: 0.6;
  font-size: 1.3em;
  margin: 0; }

.underlined-eva {
  border-bottom: 4px solid var(--eva-yellow);
  border-radius: 2px; }

.scheduled-contact {
  font-size: 1.2em; }

.eva-link {
  font-weight: bold;
  cursor: pointer;
  color: inherit; }
