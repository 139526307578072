div.animated-plus-button {
  position: relative;
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  transform-origin: center;
  margin: 0 1em;
  cursor: pointer; }
  div.animated-plus-button > div {
    background-color: var(--eva-yellow);
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    top: calc(50% - 4px); }
  div.animated-plus-button > div.moving-bar {
    transform: rotate(90deg);
    transition: transform 0.5s ease; }
  div.animated-plus-button > div.move {
    transform: rotate(180deg); }
