.live-tracker-text-input {
  font-family: "graphie", sans-serif !important;
  width: 100%;
  background-color: #e5e5e5;
  border-radius: 50px;
  border: none;
  padding: 6px 20px;
  font-size: 0.9em; }

.live-tracker-text-input-textarea {
  font-family: "graphie", sans-serif !important;
  width: 100%;
  background-color: #e5e5e5;
  border-radius: 20px;
  border: none;
  padding: 3% 20px;
  font-size: 0.9em;
  resize: none; }
