.tracking-link-menuoption-wrapper {
  font-family: "graphie", sans-serif !important;
  width: 75%;
  display: inline-block;
  text-align: left;
  padding: 2% 5px 1% 5px;
  font-size: 1.2em;
  border-bottom: 2px solid var(--eva-yellow);
  cursor: pointer; }

.tracking-link-menuoption-arrow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-mask: url(../../SVGs/back_arrow.svg) no-repeat center;
  mask: url(../../SVGs/back_arrow.svg) no-repeat center;
  float: right;
  height: 20px;
  width: 20px;
  background-color: var(--eva-yellow); }

.tracking-link-menuoption-label {
  float: left; }

.tracking-link-menuoption-wrapper-inactive {
  font-family: "graphie", sans-serif !important;
  width: 75%;
  display: inline-block;
  text-align: left;
  opacity: 0.6;
  padding: 2% 5px 1% 5px;
  font-size: 1.2em;
  border-bottom: 2px solid lightgrey; }
