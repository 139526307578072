/* STYLES FOR THE LIVE TRACKER MAP */
.live-tracker-demo-control-panel {
  position: fixed;
  height: 150px;
  width: 300px;
  z-index: 999;
  top: 100px;
  left: 20px;
  padding: 20px;
  background-color: whitesmoke;
  border-radius: 10px;
  box-shadow: 0 0 30px; }

.live-tracker-demo-control-panel .title {
  text-transform: uppercase;
  font-size: 22px;
  text-align: center; }

.live-tracker-demo-control-panel .demo-control-row {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  grid-template-rows: 1fr;
  padding: 3% 2%;
  font-size: 18px; }

.live-tracker-demo-control-panel .demo-control-row .demo-center-text {
  padding: 3% 2%;
  margin: auto; }

@media only screen and (max-device-width: 1000px) {
  .live-tracker-demo-control-panel {
    display: none; } }

.live-tracker-custom-toast {
  color: white;
  position: fixed;
  height: 60px;
  width: auto;
  left: 0;
  text-align: center;
  font-size: 1.2em;
  padding: 15px;
  padding-bottom: 10px;
  border-radius: 15px;
  z-index: 1;
  right: 15vw;
  left: 15vw;
  bottom: 40px;
  float: right; }

/* GENERAL */
.live-tracker-container {
  height: 100vh;
  font-family: "Spartan","Robotica", sans-serif !important;
  overflow-y: hidden;
  /* DESKTOP */
  /* MOBILE ADJUSTMENTS: NOTE - INCLUDES ALL OTHER CSS FORM DESKTOP */ }
  .live-tracker-container .live-tracker-header {
    width: 100%;
    background-color: var(--eva-yellow);
    height: 80px;
    padding: 20px 35px; }
  .live-tracker-container .logo-link {
    display: inline-block; }
    .live-tracker-container .logo-link img {
      height: 40px;
      width: auto; }
  .live-tracker-container .live-tracker-header-menu-btn {
    float: right;
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    cursor: pointer;
    margin-right: 3em;
    text-transform: uppercase;
    width: 100px;
    margin: auto;
    cursor: pointer; }
  .live-tracker-container .live-tracker-header-menu-btn .current {
    text-align: center; }
  .live-tracker-container .live-tracker-header-menu-btn .options {
    position: absolute;
    text-align: center;
    z-index: 10;
    text-transform: capitalize;
    border-radius: 0px 0px 10px 10px;
    width: 100px;
    margin-left: -10px;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
    background-color: white; }
  .live-tracker-container .live-tracker-header-menu-btn .options div {
    padding: 5px 10px;
    font-size: 18px; }
  .live-tracker-container .live-tracker-header-menu-btn .options div:hover {
    background-color: rgba(0, 0, 0, 0.1); }
  .live-tracker-container .live-tracker-header-menu-btn .separator {
    width: 45px;
    margin-bottom: 2px; }
  .live-tracker-container .map {
    height: calc(100% - 85px);
    width: 70%;
    float: left; }
  .live-tracker-container .live-tracker-drawer {
    min-height: calc(100% - 85px);
    max-height: calc(100% - 85px);
    height: calc(100% - 85px);
    width: 30%;
    float: right; }
  .live-tracker-container .live-tracker-smiley-img {
    margin: 10%;
    margin-top: 5%;
    width: 160px;
    height: 160px; }
  .live-tracker-container .live-tracker-overlay-black {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    display: grid; }
  .live-tracker-container .live-tracker-success-popup {
    position: absolute;
    height: 60%;
    width: 60%;
    z-index: 3;
    background-color: white;
    border-radius: 20px;
    padding: 5%;
    left: 20%;
    top: 20%; }
  .live-tracker-container .live-tracker-proxy-popup {
    position: relative;
    z-index: 3;
    background-color: white;
    border-radius: 20px;
    padding: 25px 55px;
    margin: auto; }
  .live-tracker-container .live-tracker-success-popup-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 50px;
    width: 50px;
    z-index: 4;
    cursor: pointer; }
  .live-tracker-container .live-tracker-success-popup-text {
    text-align: center;
    font-family: "Spartan","Robotica", sans-serif !important;
    color: var(--eva-yellow);
    font-size: 2em;
    float: left;
    width: 50%;
    height: 100%; }
  .live-tracker-container .live-tracker-proxy-popup-title {
    font-size: 2.2em;
    text-align: center;
    margin-bottom: 1%; }
  .live-tracker-container .live-tracker-proxy-popup-phone {
    font-size: 2em;
    text-align: center;
    color: var(--eva-yellow);
    margin: 5% 0; }
  .live-tracker-container .live-tracker-proxy-popup-text-container {
    display: grid; }
  .live-tracker-container .live-tracker-proxy-popup-text {
    font-size: 1.2em;
    margin: auto; }
  .live-tracker-container .live-tracker-success-popup-img {
    float: right;
    width: 50%;
    height: 100%;
    text-align: right; }
  .live-tracker-container .marketing-img {
    height: 40vh;
    width: 22vw;
    display: inline-block;
    margin-top: -2vh;
    cursor: pointer; }
  .live-tracker-container .live-tracker-arrow-wrapper {
    width: 100%;
    text-align: center; }
  .live-tracker-container .live-tracker-arrow-img {
    display: inline-block;
    height: 40px;
    width: 60px;
    cursor: pointer; }
  .live-tracker-container .live-tracker-proxy-popup-btn {
    margin: auto;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.2em; }
  .live-tracker-container .live-tracker-proxy-popup-btn-container {
    margin-top: 40px;
    grid-template-columns: 1fr 0.2fr 0.2fr;
    display: grid;
    width: 100%; }
  .live-tracker-container .live-tracker-proxy-popup-btn-image {
    display: inline-block; }
  @media only screen and (max-device-width: 1000px) {
    .live-tracker-container .live-tracker-header {
      width: 100%;
      background-color: var(--eva-yellow);
      height: 70px;
      padding: 17.5px 25px; }
    .live-tracker-container .live-tracker-header-menu-btn {
      font-size: 16px;
      line-height: 35px;
      margin-right: 1em;
      text-transform: capitalize;
      width: 40px;
      margin-left: 25px; }
    .live-tracker-container .live-tracker-header-menu-btn .options {
      position: absolute;
      text-align: center;
      z-index: 10;
      text-transform: capitalize;
      border-radius: 0px 0px 10px 10px;
      width: 100px;
      margin-left: -10px;
      box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
      background-color: white; }
    .live-tracker-container .live-tracker-header-menu-btn .options div {
      padding: 5px 10px;
      font-size: 18px; }
    .live-tracker-container .live-tracker-header-menu-btn .options div:hover {
      background-color: rgba(0, 0, 0, 0.1); }
    .live-tracker-container .live-tracker-header-menu-btn .separator {
      width: 45px;
      margin-bottom: 2px; }
    .live-tracker-container .language-dropdown button {
      text-transform: uppercase; }
    .live-tracker-container #help-btn {
      margin-right: 0; }
    .live-tracker-container .logo-link {
      display: inline-block; }
      .live-tracker-container .logo-link img {
        height: 35px;
        width: auto; }
    .live-tracker-container .map {
      width: 100%;
      float: none; }
    .live-tracker-container .live-tracker-drawer {
      min-height: 75%;
      max-height: 75%;
      height: 75%;
      width: 100%;
      position: absolute;
      bottom: 0;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      overflow: hidden;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -ms-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s; }
    .live-tracker-container .live-tracker-success-popup-text {
      text-align: center;
      font-family: "Spartan","Robotica", sans-serif !important;
      color: var(--eva-yellow);
      font-size: 1.2em;
      float: none;
      width: 100%;
      height: 50%;
      padding-top: 35%;
      padding-bottom: 0px; }
    .live-tracker-container .live-tracker-success-popup-img {
      float: none;
      width: 100%;
      height: 100%;
      text-align: center; }
    .live-tracker-container .marketing-img {
      height: 200px;
      width: 200px;
      display: inline-block; }
    .live-tracker-container .live-tracker-success-popup {
      height: 80%;
      width: 80%;
      padding: 5%;
      left: 10%;
      top: 15%;
      text-align: center; }
    .live-tracker-container .live-tracker-success-popup-close-btn {
      height: 30px;
      width: 30px;
      right: 10px; }
    .live-tracker-container .live-tracker-smiley-img {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 5%;
      left: 33%; }
    .live-tracker-container .live-tracker-arrow-wrapper {
      width: 100%;
      text-align: center; }
    .live-tracker-container .live-tracker-arrow-img {
      height: 20px;
      display: inline-block;
      width: 30px; }
    .live-tracker-container .reduce-drawer-live-tracker-btn {
      width: 100%;
      height: 30px;
      border: none;
      margin: 0;
      padding: 0;
      background-color: whitesmoke; }
      .live-tracker-container .reduce-drawer-live-tracker-btn .reduce-drawer-live-tracker {
        width: 30px;
        height: 30px;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        transition: transform 0.5s ease; }
      .live-tracker-container .reduce-drawer-live-tracker-btn .minimized-reducer {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg); }
    .live-tracker-container .minimized {
      min-height: 25%;
      max-height: 25%;
      height: 25%;
      width: 100%;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -ms-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s; } }
  @media only screen and (min-device-width: 1000px) and (max-device-width: 1600px) {
    .live-tracker-container .map {
      width: 60%; }
    .live-tracker-container .live-tracker-drawer {
      width: 40%; } }
  @media only screen and (max-device-width: 1000px) and (orientation: landscape) {
    .live-tracker-container .live-tracker-drawer {
      z-index: 2; } }

@media only screen and (max-device-width: 1000px) {
  .live-tracker-custom-toast {
    height: 80px; } }

.action-button {
  margin: 40px auto auto auto;
  border: 0 !important;
  font-family: "Roboto";
  font-size: 20px !important;
  font-weight: 400;
  width: 50%;
  color: white;
  height: 45px;
  border-radius: 30px !important;
  background-color: var(--eva-yellow);
  display: grid;
  cursor: pointer;
  font-weight: bold; }
