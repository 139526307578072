.orderRideWrapper {
  background-color: rgba(243, 243, 243, 1);
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-family: "Roboto";
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 50px;
}

.orderRideTitleLine {
  width: 225px;
  height: 5px;
  background-color: rgba(255, 192, 2, 1);
  margin-left: 50%;
}

.stepEllipsesWrapper {
  margin-bottom: 10px;
  display: inline-flex;
  align-items: flex-start;
}

.ellipse {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0;
  border: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.ellipseFull {
  background-color: rgba(0, 0, 0, 1);
}

.ellipse:focus, .ellipse:active {
  border: 0;
  outline: 0;
}

.stepEllipsesLine {
  width: 100px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 25px;
}

.stepNumber {
  font-size: 20px;
  font-weight: 700;
}

.formTitle {
  font-family: "Roboto";
  font-size: 25px;
  font-weight: 300;
  margin-bottom: 10px;
  height: 40px;
}

.locationForm {
  margin-left: 20%;
  margin-right: 20%;
  width: 50%;
  margin-bottom: 50px;
}

.line6 {
  width: 100px;
  height: 5px;
  background-color: rgba(255, 192, 2, 1);
  margin-bottom: 10px;
  margin-left: 50%;
}

.plannedDateLine {
  width: 225px;
  height: 5px;
  background-color: rgba(255, 192, 2, 1);
  margin-bottom: 10px;
  margin-left: 50%;
}

.plannedDateForm {
  margin-bottom: 50px;
  display: inline-flex;
  align-items: flex-start;
}

.timeContainer {
  display: inline-flex;
  height: fit-content;
  margin: 4px 0;
}

.buttonInactive {
  background-color: rgba(0, 0, 0, 1);
  margin-right: 13px;
  margin-top: 0;
  border-radius: 50px;
  padding: 16px 0;
  display: flex;
  border: 0;
  outline: none;
  transition: 0.3s;
  text-align: center;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
}

.buttonInactive:hover {
  background-color: rgba(255, 192, 2, 1);
}

.buttonActive {
  background-color: rgba(255, 192, 2, 1);
  margin-right: 13px;
  margin-top: 0;
  border-radius: 50px;
  padding: 16px 0;
  display: flex;
  align-items: center;
  border: 0;
  outline: none;
  transition: 0.3s;
  text-align: center;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
}

.evaBackground {
  background-color: rgba(255, 192, 2, 1);
  transition: 0.3s;
}

.customDropdown {
  margin-right: 16px !important;
  border-radius: 50px !important;
  padding: 0 10px 0 40px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.customDropdown p {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  color: black;
  margin: auto;
  padding-right: 15px;
}

.dropdownToggle {
  border: 0 !important;
  margin-top: 0;
  background-color: transparent !important;
}

.dropdownMenu {
  max-height: 400px !important;
  overflow-y: scroll !important;
  left: -95px !important;
}

.dropdownSeparator {
  width: 1px;
  height: 53px;
  background-color: rgba(0, 0, 0, 0.3);
  margin-right: 7px;
}

.h {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 300;
  color: rgba(196, 196, 196, 1);
  margin-top: 16px;
  margin-right: 17px;
}

.nextBtn {
  background-color: rgba(255, 192, 2, 1);
  border-radius: 50px;
  padding: 16px 40px 10px;
  min-width: 200px;
  margin: 45px auto 100px;
  display: block;
  border: 0;
  outline: none;
  color: rgba(255, 255, 255, 1);
}

.nextText {
  font-size: 25px;
  font-weight: 700;
}

.center {
  text-align: center;
  width: 100%;
}

.inputForm {
  border: 0 !important;
  font-family: "Roboto";
  font-size: 20px !important;
  font-weight: 400;
  width: 100%;
  color: gray !important;
  height: 30px ;
  border-radius: 30px !important;
  padding: 20px !important;
  margin-bottom: 30px;
}

.centerInput {
  display: block;
  text-align: center;
  padding-bottom: 30px;
  padding-top: 20px;
}

.inputFormTip {
  display: inline-block;
  border: 0 !important;
  font-family: "Roboto";
  font-size: 20px !important;
  font-weight: 400;
  width: 15%;
  color: gray !important;
  height: 30px ;
  border-radius: 30px 0 0 30px !important;
  padding: 20px !important;
}

.inputFormTipCurrency {
  display: inline-block;
  border: 0 !important;
  font-family: "Roboto";
  font-size: 20px !important;
  font-weight: 400;
  width: 13%;
  text-align: center;
  height: 30px ;
  border-radius: 0 30px 30px 0 !important;
  padding: 20px !important;
  background-color: rgba(255, 192, 2, 1);
}

textarea.inputForm {
  padding: 20px !important;
  min-height: 70px;
  height: auto;
  resize: vertical;
}

textarea.inputForm:focus {
  outline: white !important;
}

.inputForm::placeholder {
  color: rgba(196, 196, 196, 1) !important;
}

.inputFormError {
  border: 1px solid red !important;
}

.phoneDropdownButton {
  border: 0px none !important;
  border-radius: 50px 0 0 50px !important;
  background: white !important;
  padding-left: 5px !important;
}

.phoneDropdownButton:hover, .phoneDropdownButton:focus {
  border: 0px none !important;
  border-radius: 50px 0 0 50px !important;
  background: white !important;
  padding-left: 5px !important;
}

.phoneInput {
  margin-left: 45px !important;
  border-radius: 0 50px 50px 0 !important;
  width: 95% !important;
}

.optionalTitle {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  color: black;
  margin-bottom: 12px;
  margin-top: 24px;
}

.formTitleLine {
  width: 150px;
  height: 5px;
  background-color: rgba(255, 192, 2, 1);
  margin-bottom: 30px;
  margin-left: 0;
}

.subtitle {
  width: 449px;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin: auto auto 96px;
}

.rideServiceBtn, .rideServiceBtn:active, .rideServiceBtn:hover, .rideServiceBtn:focus {
  border: 0 !important;
  background-color: transparent !important;
}

.rideServiceBtnIcon {
  border: 2px solid black;
  border-radius: 50%;
}

.autoComplete {
  position: absolute;
  width: 96%;
  border-radius: 50px;
  z-index: 10;
  top: 55.5%;
}

.autoComplete .selectableList:first-child {
  border-radius: 25px 25px 0 0;
}

.autoComplete .selectableList:last-child {
  border-radius: 0 0 25px 25px;
}

.selectableList {
  background-color: white;
  margin: 0;
  padding: 15px;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 400;
}

.selectableList:hover {
  background-color: lightgray !important;
  cursor: pointer;
}

.rideIcon {
  width: 150px;
}

.grayscaleFade {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  transition: all .25s ease-in-out !important;
  -webkit-transition: all .25s ease-in-out !important;
  border: 0 !important;
}

.grayscaleFade:hover {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}

.grayscaleFade:focus {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}

.slider {
  margin: auto;
  width: 30% !important;
  max-width: 200px;
}

.switch {
  transform: rotate(90deg);
  margin-left: 100px;
  margin-top: -60px;
  position: absolute !important;
}

.switchIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 25px;
  font-weight: 1000;
  transform: rotate(90deg);
  color: white;
}

.tipInput:disabled {
  background-color: lightgray;
}

.tipPercentageSelectionContainer {
  display: inline-block;
  border-radius: 50px;
  overflow: hidden;
  background-color: rgb(254,235,161);
  width: 75%;
}

.tipPercentageSelectionItem {
  display: inline-block;
  width: 25%;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.active {
  background-color: rgb(255,192,5);
}

@media (min-width: 1280px) {
  .plannedDateContainer {
    width: 100% !important;
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .plannedDateContainer {
    width: 80% !important;
  }
}

@media (min-width: 992px) {
  .dateFormLine {
    width: 46px;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.3);
    margin-top: 28px;
    margin-right: 22px;
  }
  .dateFormSeparator {
    width: 46px;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.3);
    margin-top: 28px;
    margin-right: 22px;
  }
  .formContainer {
    width: 60% !important;
  }
  .locationFormContainer {
    width: 60% !important;
  }
  .tipInput {
    text-align: center;
    width: 20% !important;
  }
}

@media (max-width: 1280px) {
  .plannedDateForm {
    display: block;
    width: 95%;
    margin: auto !important;
  }
  .nowButton {
    width: 100% !important;
    margin: auto !important;
    display: block !important;
  }
  .dateButton {
    width: 90% !important;
    display: block !important;
    margin-bottom: 15px;
  }
  .customDropdown {
    display: inline-flex !important;
    padding: 0 0px 0 30px !important;
  }
  .dateFormSeparator {
    width: 80%;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.3);
    margin: 15px auto;
  }
  .orderRideTitleLine {
    width: 50%;
    height: 5px;
    background-color: #ffc002;
    margin: 15px auto 30px;
  }
  .locationFormContainer {
    margin: 5% auto !important;
  }
  .formContainer {
    margin: 5% auto !important;
  }
  .dropdownMenu {
    position: absolute !important;
    background-color: white !important;
  }
  .subtitle {
    width: 100% !important;
  }
  .fieldNoHeader {
    height: 0;
    margin-top: 50px;
  }
  .dropdownToggle {
    margin-left: -5px !important;
    margin-right: 5px !important;
  }
  .inputFormTip {
    width: 50%;
  }
  
  .inputFormTipCurrency {
    width: 25%;
  }
}

@media (max-width: 993px) {
  .tipInput {
    text-align: center;
    width: 30% !important;
  }
  .fieldNoHeader {
    height: 0;
    margin-top: -20px;
  }
  .phoneInputContainer {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .buttonInactive {
    padding: 16px 0;
  }
  .buttonActive {
    padding: 16px 0;
  }
  .rideIcon {
    width: 100px;
  }
  .phoneInput {
    width: 93% !important;
  }
}

@media (min-width: 540px) {
  .dateContainer {
    display: flex !important;
  }
}

@media (max-width: 540px) {
  .phoneInput {
    width: 88% !important;
  }
  .autoComplete {
    width: 92% !important;
  }
  .dateButton {
    width: 100% !important;
  }
  .slider {
    width: 40% !important;
  }
}