/* STYLES FOR THE LIVE TRACKER MAP */
/* VALUES */
:root {
  --eva-yellow: rgb(255,193,22)
; }

/* DESKTOP */
.map-container {
  height: 100%;
  position: relative; }

.center-camera-button {
  background-color: #FFF;
  border-radius: 5px;
  border: 1px solid #BBB;
  z-index: 1;
  position: relative;
  padding: 8px;
  right: 20px;
  top: 20px;
  float: right;
  width: 58px;
  height: 58px;
  cursor: pointer; }

.map-navigation-recenter-btn {
  -webkit-mask: url(../SVGs/recenter.svg) no-repeat center;
  mask: url(../SVGs/recenter.svg) no-repeat center;
  height: 42px;
  width: 42px;
  background-color: var(--eva-yellow); }
