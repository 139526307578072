.eva-y-button-wrapper {
  width: 80%;
  margin: auto;
  padding: 20px 0; }

.eva-y-button {
  background-color: var(--eva-yellow);
  border-radius: 50px;
  display: inline-block;
  color: white;
  font-family: "graphie", sans-serif !important;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  padding: 0.4em 2em;
  margin: auto; }

.eva-y-button-wrapper-round {
  padding: 3%; }

.eva-y-button-round {
  background-color: var(--eva-yellow);
  border-radius: 50px;
  display: inline-block;
  width: 50px;
  height: 50px;
  padding-top: 13px;
  color: white;
  font-family: "graphie", sans-serif !important;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer; }
